@media (max-width: 576px) {
  .scale-6 {
    zoom: 0.6;
  }
  .scale-7 {
    zoom: 0.7;
  }
  .scale-9 {
    zoom: 0.9;
  }
}
